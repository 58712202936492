<template>
  <a-spin :spinning="!hotelDataLoaded" >
    <section class="nd-layout_container nd-layout_normal hotel-detail-section">

        <a-breadcrumb class="location-nav">
          <a-breadcrumb-item>酒店预定</a-breadcrumb-item>
          <a-breadcrumb-item><a :href="`/hotel/list?cityId=`+HotelDetail.CityCode">{{ HotelDetail.CityName }}</a></a-breadcrumb-item>
          <a-breadcrumb-item>{{HotelDetail.HotelName}}</a-breadcrumb-item>
        </a-breadcrumb>
     
        <a-row :gutter="[0,12]">

          <a-col  :span="24">
            
            <div class="hotel-detail_overview">

              
              <div class="hotel-heading nd-flex space-between">
                <div>
                  <a-typography-title :level="4" :copyable="true" style="margin-bottom: 0;">{{HotelDetail.HotelName}}</a-typography-title> 
                  <a-typography-paragraph type="secondary">{{HotelDetail.HotelEnName}}</a-typography-paragraph>
                 
                </div>

                <a-rate :value="HotelDetail.Star"   disabled  />
              </div>
              <div class="nd-flex">
                <div class="hotel-detail_overview_left ">
        
                    <a-space direction="vertical" class="hotel-detail_contact">
                      <a-typography-text type="secondary">地址：{{HotelDetail.Address}} ,{{ HotelDetail.DistrictName }} ,{{ HotelDetail.CityName }}</a-typography-text>
                    <a-typography type="secondary">电话：{{HotelDetail.Tel}}</a-typography>
                    </a-space>

             
                  
                  <a-divider />

                  <!--酒店简介-->
                  <div class="hotel-detail_sumary nd-flex">
 
                    <a-typography-paragraph :strong="true" style="width: 100px;">酒店介绍</a-typography-paragraph>

                    <a-typography-paragraph :ellipsis="{ rows: 4 }" type="secondary"  :content="HotelRemark" />

                  </div>


                  <!--酒店设施-->
                  <div class="hotel-l-cont-facilities">

                    <a-tooltip  title="免费WiFi">       
                      <span class="list-wf"></span>
                    </a-tooltip>
                    <a-tooltip  title="停车场">       
                      <span class="list-pk"></span>
                    </a-tooltip>
                                              
                  </div>

                  
                </div>
                <div class="hotel-detail_overview_right ">
                
                  <photoView  v-if="hotelDataLoaded" :hotelCode="parseInt(urlParams.hotelId)" />
                </div>

              </div>
            </div>

          </a-col>     
          
          
          <a-col :span="24">
            <a-affix :offset-top="0" class="affix-section">  
              
              <hotelSearchBox v-if="hotelDataLoaded" type="detail" :propsParams="urlParams" :propsKeyword="keywordValue"  @updateHotelRate="fetchHotelRate" @updateKeywordValue="updateKeywordValue"/>  
              <div class="ant-primary-box" style="padding: 15px; position: relative;">
               
                <a-anchor class="hotel-anchor"
                  direction="horizontal"
                  :targetOffset="160"
                  :items="[
                    {
                      key: '0',
                      href: '#hotel-price',
                      title: '酒店价格',
                    },

                    {
                      key: '1',
                      href: '#hotel-detail',
                      title: '酒店介绍',
                    },

                    {
                      key: '2',
                      href: '#hotel-zhoubian',
                      title: '酒店周边',
                    },
                    {
                      key: '3',
                      href: '#hotel-sheshi',
                      title: '酒店设施',
                    },
                    {
                      key: '4',
                      href: '#hotel-zhengce',
                      title: '酒店政策',
                    },
                    {
                      key: '5',
                      href: '#hotel-shiyong',
                      title: '实用数据',
                    },
                    
                  
                  ]"
                  
                />

                <a-checkbox-group name="checkboxgroup" :options="option" @change="onCheckAllChange" style="position: absolute; right: 10px; top: 15px"/>

              </div>
              <div id="Progress" >
                  <div style="height: 2px;"></div>
                </div>
         
            </a-affix>
          </a-col>
          
          <a-col  :span="24">

              <div class="hotel-price"  id="hotel-price">
                  
                <a-empty :description="errorMessage" v-if=" RoomRates.length==0" />
                <div class="all_house_type fade" v-else>
                  <!-- 标题头部 -->
                  <ul class="house_title_head">
                      <li class="fx_all">全部房型</li>
                      <li class="fx_room">
                        房型/床型描述       
                          <a-tooltip  title="如遇房型名称、与床型信息不一致，请以此为准" :overlayInnerStyle="{color:'#8a8888'}" color="#fff" >
                            <QuestionCircleOutlined />
                          </a-tooltip>
                      </li>
                      <li class="fx_breakfast">
                        早餐
                        <a-tooltip  title="如遇房型描述与早餐信息不一致，请以此为准" :overlayInnerStyle="{color:'#8a8888'}" color="#fff" >
                            <QuestionCircleOutlined />
                          </a-tooltip>
                      </li>
                      <li class="fx_policies"> 政策</li>
                      <li class="fx_average">价格/间/夜</li>
                      <li class="fx_reservation">预订</li>
                  </ul>
                  <ul id="HouseContent" class="tab_house_content">
      
                    
                    <li class="HouseContentChild" v-for="(RoomRate,rindex) in RoomRates" :key="rindex">
                        <template v-if="RoomRate.RoomRatePlans.length>3">
                            <div v-if="RatePlanShowCount[rindex]==3" class="see_more see_more_btn1" data-state="0" @click="viewMore(rindex)">查看更多 &gt;</div>
                            <div v-else class="see_more see_more_btn1" data-state="0" @click="viewMore(rindex)">收起更多 &gt;</div>      
                        </template>           
                        <!-- 房型主图 介绍 -->

                        <div class="house_introduce">
                            <div class="house_img"></div>
                            <p>
                                <span style="color: #333;font-size: 14px;">房型：<span style="color: #693;font-size: 16px;">{{RoomRate.RoomName}}</span></span>
                                <span style="color: #333;font-size: 14px;">景观：<span style="color: #693;font-size: 16px;">{{RoomRate.RoomViewName}}</span></span>
                                <span style="color: #333;font-size: 14px;">面积：<span style="color: #693;font-size: 16px;"></span>{{RoomRate.RoomArea}}㎡</span>
                                <span style="color: #333;font-size: 14px;">禁烟房：<span style="color: #693;font-size: 16px;">{{ RoomRate.AllowSmokeName }}</span></span>
                                <span style="color: #333;font-size: 14px;">床型：<span style="color: #693;font-size: 16px;">{{RoomRate.BedTypeName}}</span></span>
                            </p>

                        </div>
                        <!-- 房型的套餐 数量 -->
                        <div class="house_number">
                            <!-- 房型套餐  -->
                            <ul id="house_viewport1" class="house_viewport_ul">
                                
                                
                      
                                <!-- 套餐1  套餐显示 三条 超出隐藏 -->
                                <li v-for="(RatePlan,index) in RoomRate.RoomRatePlans" :key="index" v-show="(index<RatePlanShowCount[rindex])">
                                    <span class="room_index"><span>{{index+1}}</span></span>
                                    <div class="room_info ">
                                        <p style="color: #333;font-size: 12px;">{{RatePlan.RoomRatePlanName}}</p>
                                        <p class="FixPeopleName">{{RatePlan.FixPeopleName}}</p>
                                        <p v-if="RatePlan.Quality">
                                          <a-tooltip  title="优质准确率高，库存稳定，响应及时" :overlayInnerStyle="{color:'#8a8888'}" color="#fff" >
                                          <InsuranceTwoTone twoToneColor="#669933" style="font-size: 18px;" />
                                          </a-tooltip>                                  
                                        </p>

                                    </div>

                                    <div class="breakfast_info ">
                                      <!--"早餐份数(1：无早；2：单早；3：双早；4：三早；5：床 位早；6：四早；7：五早；8：六早) )"-->
                                      <a-tooltip v-if="RatePlan.HasBreakfast>1"  :title="`${RatePlan.HasBreakfastName}/日`" :overlayInnerStyle="{color:'#8a8888'}" color="#fff" >
                                        <span class="breakfast-text">{{RatePlan.HasBreakfastName}}</span>
                                      </a-tooltip>
                                      <span v-else>  {{RatePlan.HasBreakfastName}}</span>                         

                                    </div>

                                    <div class="policy_info">
                                      <p><span class="policy_info_not-refundable">{{RatePlan.RoomPrices[0].Cancellation}}</span></p>
                                      <!--
                                        <p><span class="policy_info_refundable">限时取消</span>
                                        <span class="policy_info_text"> 2024-06-25 23:00:00(酒店当地时间UTC+7)之前免费取消</span>
                                      </p>
                                      -->
                                      
                                    </div>
                                    <div class="average_info ">

                                      
                                        
                                      <p class="avg-price">
                                          <sub>均</sub>

                                      <a-popover trigger="click">
                                        <template #content>
                                          <table class="nd-list-table">
                                            <template v-for="(week, weekIndex) in groupedData(RatePlan.RoomPrices)" :key="weekIndex">
                                                <thead>
                                                    <tr>
                                                        <td v-for="date in week.dates" :key="date">{{ date }}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td v-for="price in week.prices" :key="price">{{ price }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                          </table>

                                          <div class="nd-flex">
                                            <span>总价：</span>
                                            <a-typography-text type="danger">CNY {{RatePlan.RoomPrices.reduce((sum, p) => sum + p.Price, 0)}}</a-typography-text>
                                              <a-typography-text>（{{condition.RoomCount}}间）</a-typography-text>
                                          </div>


                                        </template>
                              

                                          <span >CNY <b>{{(RatePlan.RoomPrices.reduce((sum, p) => sum + p.Price, 0)/RatePlan.RoomPrices.length).toFixed(2)}}</b></span>
                                    
                                      </a-popover>


                                      </p>


                                      <p class="total-price">
                                        <sub>总</sub>
                                        <span>CNY<b>{{RatePlan.RoomPrices.reduce((sum, p) => sum + p.Price, 0)}}</b></span>
                                      </p>
                                    </div>
                                    <div class="fx_id_button ">
                                      <a-button type="primary" @click="onClickPreBook(RatePlan.RoomRatePlanId)" >立即预订</a-button>
                                    </div>
                                    

                                </li>



                                
                            </ul>
                        </div>

                    </li>
                  </ul>

                </div>                
      

              </div>
          </a-col>
        
          <a-col  :span="24">

            <div class="nd-panel-body" id="hotel-detail">

              <div class="hotel-detail"  >
                <a-divider orientation="left">酒店介绍</a-divider>
                <div v-html="HotelRemark.replace(/\n/g, '<br>').replace(/\r/g, '<br>')" ></div>
              </div>

            </div>
          </a-col>

          <a-col  :span="24">

            <div class="nd-panel-body" id="hotel-zhoubian">


              <div class="hotel-zhoubian">
                <a-divider orientation="left">酒店周边</a-divider>
                <div style="min-height: 200px;">
                  <a-list v-if="HotelZhoubian.length>0" item-layout="horizontal" :data-source="HotelZhoubian">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #title>
                            {{ item.TypeName }}
                          </template>
                          <template #description v-if="item.Interpretation.length>0">
                            <a-tag color="pink"  v-for="(tag) in item.Interpretation" :key="tag"> {{ tag }}</a-tag>
                       
                          </template>
                          <template #description  v-else>
                           
                            <a-tag color="cyan" >数据暂未收录</a-tag>
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </template>
                  </a-list>
                  
                  </div>
              </div>



            </div>
          </a-col>


          <a-col  :span="24">

            <div class="nd-panel-body" id="hotel-sheshi">



              <div class="hotel-sheshi" >
                <a-divider orientation="left">酒店设施</a-divider>
                <div style="min-height:200px;">
                  <a-list v-if="HotelSheshi.length>0" item-layout="horizontal" :data-source="HotelSheshi">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #title>
                            {{ item.TypeName }}
                          </template>
                          <template #description v-if="item.Interpretation.length>0">
                            <a-tag color="pink"  v-for="(tag) in item.Interpretation" :key="tag"> {{ tag }}</a-tag>
                       
                          </template>
                          <template #description  v-else>
                           
                            <a-tag color="cyan" >数据暂未收录</a-tag>
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </template>
                  </a-list>                  



                </div>
              </div>

       


            </div>
          </a-col>

          <a-col  :span="24">

            <div class="nd-panel-body" id="hotel-zhengce">

              <div class="hotel-zhengce" >
                <a-divider orientation="left">酒店政策</a-divider>
                <div style="min-height: 200px;">
                  <a-list v-if="HotelZhengce.length>0" item-layout="horizontal" :data-source="HotelZhengce">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #title>
                            {{ item.TypeName }}
                          </template>
                          <template #description v-if="item.Interpretation.length>0">
                            <a-tag color="pink"  v-for="(tag) in item.Interpretation" :key="tag"> {{ tag }}</a-tag>
                       
                          </template>
                          <template #description  v-else>
                           
                            <a-tag color="cyan" >数据暂未收录</a-tag>
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </template>
                  </a-list>                  
                  



                </div>
              </div>



            </div>
          </a-col>

          <a-col  :span="24">

            <div class="nd-panel-body" id="hotel-shiyong">

              <div class="hotel-shiyong" >
                <a-divider orientation="left">实用数据</a-divider>
                <div style="min-height: 200px;">
                  <a-list v-if="HotelShiyong.length>0" item-layout="horizontal" :data-source="HotelShiyong">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #title>
                            {{ item.TypeName }}
                          </template>
                          <template #description v-if="item.Interpretation.length>0">
                            <a-tag color="pink"  v-for="(tag) in item.Interpretation" :key="tag"> {{ tag }}</a-tag>
                      
                          </template>
                          <template #description  v-else>
                          
                            <a-tag color="cyan" >数据暂未收录</a-tag>
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </template>
                  </a-list>                  
                  



                </div>
              </div>



            </div>
          </a-col>



        
        </a-row>

       






        

    </section>
  </a-spin>
</template>
<script>
import '@/assets/styles/hotel.css'
import {InsuranceTwoTone,QuestionCircleOutlined} from '@ant-design/icons-vue';
import hotelSearchBox from '@/components/hotelSearchBox.vue';
import photoView from '@/components/photoView.vue';
import router from '@/assets/router';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // 引入NProgress的样式
import '@/assets/styles/nprogress-custom.css'; // 引入NProgress的样式
export default {
  name: 'HotelHome', 
  data(){
    return{
      option:[
  {
    label: '可取消',
    value: 'cancel'
  },
  {
    label: '含早餐',
    value: 'bf',
  },
  ],
    errorMessage:"",
    keywordValue:{},
    hotelDataLoaded:false,
    HotelDetail:{},
    RoomRates:[],
    condition:{
        HotelId: 0,
        CheckIn:"",
        CheckOut: "",
        Breakfast:false,
        Cancelling:false,
        RoomCount: 1,
        AdultCount:2,
        ChildCount: 0,
        ChildAgeList:"",

      },
    HotelRemark:"",
    HotelZhoubian:[],
    HotelSheshi:[],
    HotelZhengce:[],
    HotelShiyong:[],
    RatePlanShowCount:[],//价格计划默认显示条数
    } 
    
  },
  created(){ 
    
    this.fetchHotelDetail()
    

  },
  mounted() {
    NProgress.configure({ parent: `#Progress` ,showSpinner:false });
    document.title = this.$route.meta.title;
  },
  components: {
    hotelSearchBox,
    photoView,
    InsuranceTwoTone,
    QuestionCircleOutlined
  },
  computed: {
    urlParams() {
      const query = this.$route.query;
      //设置默认参数
      if(!query.checkInDate) query.checkInDate=dayjs().add(1, 'day').format('YYYY-MM-DD')
      if(!query.checkOutDate) query.checkOutDate=dayjs().add(2, 'day').format('YYYY-MM-DD')
      if(!query.roomCount) query.roomCount=1
      if(!query.adultCount) query.adultCount=2
      if(!query.childCount) query.childCount=0
      return  query;
    }

  },
  methods: {
    //重新分组价格日期  7天一组
    groupedData(roomPricesDetail) {
        let grouped = [];
        let tempGroup = { dates: [], prices: [] };
        roomPricesDetail.forEach((item, index) => {
            tempGroup.dates.push(item.PriceDate);
            tempGroup.prices.push(`${item.Price}`);
            if ((index + 1) % 7 === 0 || index === roomPricesDetail.length - 1) {
                grouped.push(tempGroup);
                tempGroup = { dates: [], prices: [] };
            }
        });
        return grouped;
    },
    //查看更多房型计划
    viewMore(index){
      console.log(index)
      if(this.RatePlanShowCount[index]==3)
        this.RatePlanShowCount[index]=100
      else
      this.RatePlanShowCount[index]=3
      console.log( this.RatePlanShowCount)
    },


    onClickPreBook(roomRatePlanId){
      const queryPara={     
                        hotelId: this.condition.HotelId,       
                        roomRatePlanId: roomRatePlanId,       
                        checkInDate: this.condition.CheckIn,
                        checkOutDate: this.condition.CheckOut,
                        roomCount: this.condition.RoomCount,             
                        adultCount: this.condition.AdultCount,
                        childCount: this.condition.ChildCount
                      }
      if(this.condition.ChildCount>0)
        queryPara.childAgeList =this.condition.ChildAgeList;

        console.log(queryPara)
      //  return
        router.push({
            name: 'HotelOrderBooking',
            query: queryPara
        });
    },
    updateKeywordValue(value){
      //console.log("Path",router.currentRoute.value.path)
      //console.log("query ",router.currentRoute.value.query )
      let query =  { ...router.currentRoute.value.query} ;
      query.hotelId = value.id;
      console.log("newsquery",query)

      //酒店变化，则刷新酒店数据
      if(value.type=="hotel"){
         router.push({ path: router.currentRoute.value.path, query: query }).then(() => {
        // 在导航完成后刷新页面
        window.location.reload();
      });

      }
      //this.urlParams.cityId=value.id
      //router.push(router.currentRoute.fullPath);
      //window.location.reload();
      //
      
    },

    onCheckAllChange(e){
       
    
       if(e.includes('bf')){
         this.condition.Breakfast=true
       }else{
         this.condition.Breakfast=false
       }
       if(e.includes('cancel')){
         this.condition.Cancelling=true
       }else{
         this.condition.Cancelling=false
       }
       this.fetchHotelRate()
      
    },
    //获取酒店详细
    async fetchHotelDetail(hotelId) {       
       try {   
         const response = await this.$axios.post('/hotelContent', { "HotelCode":hotelId??parseInt(this.urlParams.hotelId), "ExcludeRoomInfo": true } );
         
         if(response.data.RetCode=="Fail"){
          message.error(response.data.RetMessage,1.5,()=>{ 
            router.go(-1) 
            setTimeout(() => {
              window.location.reload();
            }, 100);  
          })   
         // window.location.reload();       
         }else{
          this.fetchHotelRate()
          this.HotelDetail=response.data.Hotels[0]
            this.HotelRemark=response.data.Hotels[0].HotelRemark


          // 创建一个映射表，用于快速查找 AttributesData 中的数据
          const attributesMap = new Map();
          response.data.Hotels[0]?.Attributes?.forEach(attr => {
              attributesMap.set(attr.Name, attr);
          });

          // 处理周边设施
          const zhoubianNames = ['airport', 'busstation', 'medical', 'pharmacy', 'atm'];
          zhoubianNames.forEach(name => {
              const attr = attributesMap.get(name);
              if (attr) {
                  this.HotelZhoubian.push(attr);
              }
          });

          // 处理设施
          const sheshiNames = ['network', 'traffic', 'entertainment', 'food', 'convenient', 'otherfacilities', 'allsuport', 'landmovement', 'childfacilities', 'language'];
          sheshiNames.forEach(name => {
              const attr = attributesMap.get(name);
              if (attr) {
                  this.HotelSheshi.push(attr);
              }
          });

          // 处理政策
          const zhengceNames = ['checkinout', 'policy', 'service', 'childbed'];
          zhengceNames.forEach(name => {
              const attr = attributesMap.get(name);
              if (attr) {
                  this.HotelZhengce.push(attr);
              }
          });

          
          // 处理使实用数据
          const shiyongNames = ['trafficset', 'accommodation', 'service', 'special'];
          shiyongNames.forEach(name => {
              const attr = attributesMap.get(name);
              if (attr) {
                  this.HotelShiyong.push(attr);
              }
          });

          
  
          
            
            this.keywordValue={
              type:"hotel",
              id:parseInt(this.urlParams.hotelId),
              name:response.data.Hotels[0].HotelName
            }
            this.hotelDataLoaded = true;

         }

         //console.log(response.data)

       } catch (error) {
         console.error('获取数据失败:', error);
       }
     },
     //获取酒店价格
    async fetchHotelRate(params) {  

      NProgress.start();
      var queryParams=this.urlParams
      if(params)   //params 从组件传过来的参数
        queryParams=params

      //console.log("queryParams:",queryParams)
      this.RoomRates=[]
      this.hotelLoading = true;   

      this.condition.HotelId=parseInt(queryParams.hotelId)
      this.condition.CheckIn=queryParams.checkInDate
      this.condition.CheckOut=queryParams.checkOutDate
      this.condition.RoomCount=queryParams.roomCount
      this.condition.AdultCount=queryParams.adultCount
      this.condition.ChildCount=queryParams.childCount
      if(queryParams.childCount>0)
        this.condition.ChildAgeList =queryParams.childAgeList


       try {   
         const response = await this.$axios.post('/hotelsV3',this.condition );
         NProgress.done();
         if(response.data.Hotels.length>0){
            if(response.data.Hotels[0].Rooms.length>0){
              this.RoomRates=response.data.Hotels[0].Rooms
              this.RatePlanShowCount=Array.from({ length: response.data.Hotels[0].Rooms.length }, () => (3)) //每个房型价格计划默认显示条数3
            }else{
              this.errorMessage="很抱歉，该酒店暂无房型报价"
            }

         }else{
           this.errorMessage="很抱歉，该酒店暂无房型信息"
         }


       } catch (error) {
         console.error('获取数据失败:', error);
       }
     },

    handleSelect(city) {
      console.log('Selected city:', city);
      // 处理选中的城市逻辑
    }
  }
}
</script>
<style scoped>

.all_house_type {

  background: #fff;
  border: 2px solid #669933;
  border-radius: 4px;
  -webkit-transition: all 300ms linear 700ms;
  transition: all 300ms linear 700ms;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.all_house_type .house_title_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #669933;
  color: #fff;

}
.all_house_type .house_title_head li {
  width: 220px;
  border-bottom:1px solid #ddd;
  padding: 9px 10px;
}
.all_house_type .house_title_head .fx_all {
 text-align: center;
  width: 165px;
}
.all_house_type .house_title_head .fx_room {
  text-align: center;
  width: 350px;
}
.all_house_type .house_title_head .fx_breakfast {

  width: 120px;
}
.all_house_type .house_title_head .fx_policies {

  width: 190px;
}

.all_house_type .house_title_head .fx_average {
  text-align: right;
  width: 190px;
}
.all_house_type .house_title_head .fx_reservation {
  text-align: center;
  width: 180px;
}

.all_house_type .tab_house_content li {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  border-bottom: 1px solid #ddd
}
.all_house_type .tab_house_content li .see_more {
  position: absolute;
  bottom: 6px;
  left: 14px;
  cursor: pointer;
  color: #39c573;
  width: 140px;
  text-align: center;
  padding-top: 4px;
  border-top: 1px solid #eee;
}
.all_house_type .tab_house_content .house_introduce {
  height: 100%;
  padding: 14px 12px;
}
.all_house_type .tab_house_content .house_introduce .house_img {
  margin-bottom: 10px;
  background: #eee;
}
.all_house_type .tab_house_content .house_introduce p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 140px;
  margin-bottom: 20px;
}
.all_house_type .tab_house_content .house_introduce p span {
  line-height: 20px;
}
.all_house_type .tab_house_content .house_number {
  width: 100%;

  border-left: 1px solid #ddd;
}
.all_house_type .tab_house_content .house_number .house_viewport_ul {
  overflow: hidden;
}
.all_house_type .tab_house_content .house_number ul li {
  height: 85px; 
  font-size: 14px;
  border-bottom:  1px solid #eee;
}
.all_house_type .tab_house_content .house_number ul li.hidden{display: none;}
.all_house_type .tab_house_content .house_number ul li:hover{background: #f7f6f6}
.all_house_type .tab_house_content .house_number ul li .room_info {
  width: 350px;
  display: flex;
  padding: 10px 0 10px 10px;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.all_house_type .tab_house_content .house_number ul li .room_info .FixPeopleName {
  font-size: 12px;
  color: #6fd691;
}


.all_house_type .tab_house_content .house_number ul li .breakfast_info {
  
  padding: 30px 0 10px 10px;

  width: 120px;

  flex-direction: column;
}
.all_house_type .tab_house_content .house_number ul li .breakfast-text {
  color: #00b63d;
  border-bottom: dotted 1px #00b63d;
  cursor: pointer;
}

.all_house_type .tab_house_content .house_number ul li .policy_info {
  width: 190px;
  padding: 10px 0 10px 10px;
  position: relative;
  overflow: hidden;
  display: table;

}
.policy_info_not-refundable{color: #ff5256}
.policy_info_refundable{color: #00b63d}
.all_house_type .tab_house_content .house_number ul li .policy_info p{  display: table-cell; vertical-align: middle;}
.all_house_type .tab_house_content .house_number ul li .policy_info .policy_info_text{font-size: 12px; display: block; text-align:left; color: #75727298}

.all_house_type .tab_house_content .house_number ul li .average_info {
  padding: 10px;
  padding-top: 20px;
  width: 190px;
  text-align: right

}
.all_house_type .tab_house_content .house_number ul li .average_info .avg-price {
  font-size: 16px;
  color: #ff5256;
  margin-bottom: 10px;

}
.all_house_type .tab_house_content .house_number ul li .average_info .avg-price span{  border-bottom: dotted 1px #ff5256;
  cursor: pointer;}
.all_house_type .tab_house_content .house_number ul li .average_info sub{font-size: 8px;}
.all_house_type .tab_house_content .house_number ul li .average_info b{font-weight: 700}
.all_house_type .tab_house_content .house_number ul li .average_info .total-price {
color: #3a3a3a98;
  font-size: 12px;
}



.all_house_type .tab_house_content .house_number ul li .fx_id_button {
  padding-top: 25px;
  text-align: center;
  width: 180px;
}

.all_house_type .tab_house_content .house_number ul li .room_index {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  width: 0;
  height: 0;
  border-top: 20px solid #b6b6b698;
  border-right: 20px solid transparent;
}
.all_house_type .tab_house_content .house_number ul li .room_index span {
  position: relative;
  top: -20px;
  left: 2px;
  color: #fff;
}
.all_house_type .tab_house_content .house_number ul li:hover .room_index {
  border-top: 20px solid #00b63d;
}
.hide{display: none;}
</style>